<template>
  <v-card
    class="mx-auto"
    max-width="800"
    rounded="false"
    flat
  >
    <v-card-actions>   
        <v-btn
          depressed
          color="#EEEEEE"
          id="ome_btn"
          to="/viceome"
        >
        ... více o mě
        </v-btn>
      
        <v-btn
          depressed
          color="#EEEEEE"
          to="/obrazy"
        > 
        obrazy
        </v-btn>

        <v-btn
          depressed
          color="#EEEEEE"
          to="/grafika"
        >
        grafika
        </v-btn>

        <v-btn
          depressed
          color="#EEEEEE"
          to="/sochy"
        >
        sochy
        </v-btn>

        <v-btn
          depressed
          color="#EEEEEE"
          to="/volna"
        >
        volná tvorba
        </v-btn>

        <v-btn
          depressed
          color="#EEEEEE"
          to="/kontakt"
        >
        kontakt
        </v-btn>
    </v-card-actions>  
  </v-card>
</template>

<script>

export default {
  name: 'Menu',
  data: () => ({
  }),

}
</script>

<style scoped>

.col{
  padding: 0;
  margin: 0;
}

.v-card__subtitle{
  padding: 0;
}
.v-card__title{
  position: absolute;
  top: 510px;
  left: 10px;
  font-weight: 900;
  font-size: 25px;
}
.v-card__actions{
  justify-content: flex-end;
  background-color:#EEEEEE;
  padding: 0;
}
.row{
  margin-bottom: 20px;
}
ul{
  padding-left: 30px;
}
#ome_btn{
  position: absolute;
  left: 0;
}
.home_icon{
  width: 5%;
  padding-right: 5px;
}
h2{
  margin-bottom: 5px;
}

</style>